import { Page } from "@atoms/layout/page";
import { Base, Info, InfoSmall, Title } from "@atoms/text";
import ProductCard, { ProductActions } from "@components/product";
import {
  getRouteAdvancedSearch,
  useProducts,
} from "@features/products/state/use-products";
import { ROUTES } from "@features/routes";
import { useAutoFocus } from "@features/utils/auto-focus";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { usePersistedState } from "@features/utils/hooks/use-local-storage";
import { formatSearchValues } from "@molecules/form/utils";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SearchFilters } from "./filters";
import { ProductsApiClient } from "@features/products/api-client/api-client";
import { TableGridSwitch } from "@molecules/table/table-grid-switch";
import { Column } from "@molecules/table/table";
import { ProductTypeShort } from "@features/products/types";
import { BookRender } from "@atoms/book";
import { useRecoilValue } from "recoil";
import { SearchModeGestion } from "@features/products/state/store";
import { getFilters } from "@features/product-forms/product-filters";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import Barcode from "react-barcode";
import Env from "@config/environment";
import { findDifferentKeys, isMobileDevice } from "@features/utils/divers";
import { getCommonProductTooltip } from "@features/utils/format/strings";

export const ProductsPage = () => {
  const { t } = useTranslation();
  const { initializeFocus } = useAutoFocus();
  const { products, search, loading, productFilters } = useProducts();
  const modeGestion = useRecoilValue(SearchModeGestion);
  const [freshRedirect, setFreshRedirect] = useState(true);
  const { current } = useShopLocations();
  const [autoSearch, setAutoSearch] = useState(true);

  const navigate = useNavigate();

  const [value, setValue] = usePersistedState<any>("user", "product-filters", {
    type: "all",
    external_db: Env.defaultExternalDB,
  });

  const [saveAdvanced, setSaveAdvanced] = useState();

  const [difference, setDifference] = useState<string[]>([]);

  const [gridMode, setGridMode] = usePersistedState(
    "user",
    "product-search-grid-mode",
    {
      activated: true,
    }
  );

  const [numPage, setNumPage] = usePersistedState(
    "user",
    "product-search-num-page",
    1
  );

  const [currentOrder, setCurrentOrder] = usePersistedState<any>(
    "user",
    "product-order",
    {
      orderBy: "pertinence",
      orderDir: "DESC",
    }
  );

  const [searchScroll, setSearchScroll] = usePersistedState<any>(
    "user",
    "product-scroll",
    { pos: 0 }
  );

  const [didSearch, setDidSearch] = useState(false);

  const getSearchQuery = useCallback(
    (
      { page, perPage, orderBy, orderDir } = {
        page: 1,
        perPage: 25,
        orderBy: currentOrder.orderBy,
        orderDir: currentOrder.orderDir,
      },
      specialDesig?: string
    ) => {
      const { type, ...rest } = value;

      const toSend = {
        ...(value._advanced
          ? {
              ...formatSearchValues(rest),
            }
          : {
              query: value.designation,
            }),

        queryExtendedAtBd: value.external_db,

        orderBy: orderBy || "pertinence",
        orderDir: orderDir as "DESC" | "ASC",
        pageNumber: page,
        pageSize: perPage,
      };

      if (specialDesig) {
        if (toSend._advanced) toSend.designation = specialDesig;
        else toSend.query = specialDesig;
      }

      return toSend;
    },
    [value, currentOrder]
  );

  const handleSearch = useCallback(
    async (
      attrs?: {
        page: number;
        perPage: number;
        orderBy: string;
        orderDir: string;
      },
      resetPagination?: boolean,
      specialDesig?: string
    ) => {
      if (!value._advanced && !value.designation && !specialDesig) return;
      setDidSearch(true);
      const { type } = value;
      await search(
        getSearchQuery(attrs, specialDesig),
        value._advanced ? type : "",
        resetPagination
        //(attrs?.page || 1) === 1,
      );
    },
    [getSearchQuery, search, value]
  );

  useControlledEffect(() => {
    if (!freshRedirect && difference.includes("type")) {
      handleSearch(
        {
          orderBy: currentOrder.orderBy,
          orderDir: currentOrder.orderDir,
          page: 1,
          perPage: 25,
        },
        true
      );
      setNumPage(2);
    }
  }, [value.type]);

  useControlledEffect(() => {
    if (difference.includes("external_db")) {
      handleSearch(
        {
          orderBy: currentOrder.orderBy,
          orderDir: currentOrder.orderDir,
          page: 1,
          perPage: 25,
        },
        true
      );
      setNumPage(2);
    }
  }, [value.external_db]);

  useControlledEffect(() => {
    if (
      autoSearch &&
      !freshRedirect &&
      !difference.includes("external_db")! &&
      !difference.includes("_advanced") &&
      !difference.includes("type") &&
      difference.length !== 0
    ) {
      handleSearch(
        {
          orderBy: currentOrder.orderBy,
          orderDir: currentOrder.orderDir,
          page: 1,
          perPage: 25,
        },
        true
      );

      setNumPage(2);
    }
  }, [value]);

  useControlledEffect(() => {
    if (
      value._advanced !== saveAdvanced &&
      !freshRedirect &&
      difference.includes("_advanced")
    ) {
      initializeFocus();
      setSaveAdvanced(value._advanced);
      handleSearch(
        {
          orderBy: currentOrder.orderBy,
          orderDir: currentOrder.orderDir,
          page: 1,
          perPage: 25,
        },
        true
      );

      setNumPage(2);
    }
  }, [value._advanced]);

  useControlledEffect(() => {
    if (!freshRedirect) setNumPage(1);
    //Pour nettoyer les champs non utilisés
    if (Object.values(value).some((el) => el === "" || el === null)) {
      const filtered = Object.keys(value).reduce(
        (acc: { [key: string]: any }, key) => {
          if (value[key] !== "" && value[key] !== null) {
            acc[key] = value[key];
          }
          return acc;
        },
        {}
      );

      setValue(filtered);
    }
  }, [value]);

  useControlledEffect(() => {
    const timer = setTimeout(() => {
      setFreshRedirect(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const recognitionEndedListener = (event: Event) => {
      handleSearch(undefined, true, (event as CustomEvent).detail.text);
    };
    window.addEventListener(
      "speechRecognitionDetected",
      recognitionEndedListener
    );
    return () => {
      window.removeEventListener(
        "speechRecognitionDetected",
        recognitionEndedListener
      );
    };
  });

  const columns: Column<ProductTypeShort>[] = [
    {
      title: "Articles",
      render: (p, { responsive }) =>
        responsive ? (
          <div className="text-right " onClick={() => {}}>
            <Base data-tooltip={p.designation}>{p.designation}</Base>
            <br />
            <Info>{p.id}</Info>
          </div>
        ) : (
          <div
            className="flex flex-row items-center space-x-4 cursor-pointer"
            onClick={() => {}}
          >
            <div className="w-10 print:hidden">
              <BookRender src={p.imageURL} productType={p.modeGest} />
            </div>

            <div className="flex flex-col ">
              <Base
                className="text-ellipsis print:whitespace-normal"
                data-html={true}
                data-tooltip={getCommonProductTooltip(p, p.modeGest)}
              >
                {p.designation}
              </Base>
              <Info>{p.id}</Info>
            </div>
          </div>
        ),
    },
    {
      title: "Code Barre",
      orderable: false,
      render: (p) => (
        <div className="w-full flex justify-start ">
          <Barcode value={p.id} height={20} width={1.3} />
        </div>
      ),
    },
    {
      title: "Informations",
      render: (p) => (
        <div className="grow flex flex-row">
          <div className="grow flex flex-col space-y-1 my-1">
            <InfoSmall className="whitespace-nowrap overflow-hidden text-ellipsis">
              {p.qteDispoVente} disponible à la vente{" "}
              {p.qteDispoVente !== p.qteDispoVenteFutur
                ? `(${p.qteDispoVenteFutur} future)`
                : ""}
            </InfoSmall>
            {p.dispoHabit && (
              <InfoSmall className="whitespace-nowrap overflow-hidden text-ellipsis">
                {p.dispoHabit} - {p.fournHabit}
              </InfoSmall>
            )}
            <InfoSmall>
              {p.prixPublic} {current?.devisSymb || "€"} TTC PU
            </InfoSmall>
            <InfoSmall>Rayon: {p.libRayon}</InfoSmall>
          </div>
        </div>
      ),
    },
    {
      title: "Actions",
      render: (p) => (
        <div className="w-full flex gap-2">
          <ProductActions product={p} />
        </div>
      ),
    },
  ];

  return (
    <Page
      onScroll={(e) => {
        setSearchScroll({ pos: e.currentTarget.scrollTop });
      }}
    >
      <Title>{t("products.title")}</Title>

      <div className="pt-1">
        <SearchFilters
          advanced={value._advanced}
          onSearch={(machin) => {
            if (machin !== undefined) {
              handleSearch(undefined, true, machin);
              return;
            } else {
              handleSearch(undefined, true);
            }
            if (document.activeElement) {
              (document.activeElement as HTMLInputElement).blur();
            }
          }}
          onChange={(v) => {
            if (
              v.designation !== value.designation ||
              (!value._advanced && v.type === "all")
            ) {
              setAutoSearch(false);
            } else {
              setAutoSearch(true);
            }

            if (v.type !== value.type) {
              v.type === "all"
                ? setValue([...productFilters.all])
                : setValue([...productFilters.all, ...productFilters[v.type]]);
            }
            setDifference(findDifferentKeys(v, value));
            setValue(v);
          }}
          value={value}
        />
      </div>
      <br />

      {!(!didSearch && !products.items.length) && (
        <TableGridSwitch
          gridMode={gridMode.activated}
          forceGridResponsive={true}
          headerPagination={true}
          onChangeMode={(v) => {
            setGridMode({ activated: v });
          }}
          anchorPos={searchScroll.pos}
          name="Produits"
          scrollable={true}
          onFetchExportData={async ({ page, perPage, order, orderBy }) => {
            if (value._advanced) {
              const modeGest =
                current?.items?.find((el) => el.codeTypeProd === value.type)
                  ?.modeGest || "P";
              return (
                await ProductsApiClient.getProductsAdvanced(
                  getSearchQuery({
                    page: page,
                    perPage: perPage,
                    orderBy: getFilters(modeGestion).map((f) => f.key)[
                      orderBy || 0
                    ],
                    orderDir: order || "DESC",
                  }),
                  getRouteAdvancedSearch(modeGest),
                  value.type
                )
              ).items;
            } else {
              return (
                await ProductsApiClient.getProducts(
                  getSearchQuery({
                    page: page,
                    perPage: perPage,
                    orderBy: getFilters(modeGestion).map((f) => f.key)[
                      orderBy || 0
                    ],
                    orderDir: order || "DESC",
                  })
                )
              ).items;
            }
          }}
          exportDefaultHeader={[
            "designation",
            "id",
            "qteDispoVente",
            "qteDispoVenteFutur",
            "dispoHabit",
            "fournHabit",
            "libRayon",
          ]}
          orderables={getFilters(modeGestion).map((f) => f.text)}
          showPagination={isMobileDevice() ? "simple" : "full"}
          onClick={(product) => {
            if (!window.getSelection()?.toString()) {
              navigate(
                ROUTES.Product.replace(/:type/, product.modeGest)
                  .replace(/:ean/, product.id)
                  .replace(/:referGestion/, product.referGestion.toString())
              );
            }
          }}
          data={products.items}
          moreButtonActiv={
            products.pageNumber ===
              Math.ceil(products.totalRows / products.pageSize) ||
            products.totalRows === 0 ||
            products.totalRows < 0
              ? false
              : true
          }
          loading={loading}
          renderGrid={(product) => (
            <ProductCard key={product.id} product={product} />
          )}
          tableColumns={columns}
          total={products.totalRows}
          initialPagination={{
            perPage: 25,
            page: numPage,
            order: currentOrder.orderDir,
            orderBy: getFilters(modeGestion).findIndex(
              (f) => f.key === currentOrder.orderBy
            ),
          }}
          onRequestData={async ({ page, perPage, order, orderBy }) => {
            setCurrentOrder({
              orderBy: getFilters(modeGestion).map((f) => f.key)[orderBy || 0],
              orderDir: order || "DESC",
            });

            if (!freshRedirect && products.items.length > 0) {
              await handleSearch(
                {
                  page:
                    orderBy !== undefined &&
                    currentOrder.orderBy ===
                      getFilters(modeGestion).map((f) => f.key)[orderBy] &&
                    currentOrder.orderDir === order
                      ? page
                      : 1,
                  perPage,
                  orderBy: getFilters(modeGestion).map((f) => f.key)[
                    orderBy || 0
                  ],
                  orderDir: order || "DESC",
                },
                !isMobileDevice()
              );
              if (
                !(
                  orderBy !== undefined &&
                  currentOrder.orderBy ===
                    getFilters(modeGestion).map((f) => f.key)[orderBy]
                ) ||
                currentOrder.orderDir !== order
              ) {
                setNumPage(1);
              } else {
                setNumPage(page);
              }
            }
            if (isMobileDevice()) {
              const el = document.getElementById("BIDULE");
              if (el)
                el.scrollIntoView({
                  behavior: "smooth",
                  block: "end",
                });
            }
          }}
        />
      )}
    </Page>
  );
};
