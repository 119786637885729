import { DropdownButton } from "@atoms/dropdown";
import { Checkbox } from "@atoms/input/input-checkbox";
import Select from "@atoms/input/input-select";
import { Loader } from "@atoms/loader";
import { Base, Info, InfoSmall } from "@atoms/text";
import {
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  CogIcon,
  DownloadIcon,
  TableIcon,
} from "@heroicons/react/outline";
import _ from "lodash";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TablePagination, TablePaginationSimple } from "./pagination";
import { Button } from "@atoms/button/button";
import { Modal } from "@atoms/modal/modal";
import { TableExportModal } from "./export-modal";
import { TableOptionsModal } from "./options-modal";
import React from "react";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { doDivsOverlap, swapArrayElements } from "@features/utils/divers";

export type RenderOptions = {
  responsive?: boolean;
};

export type Column<T> = {
  title?: string | ReactNode;
  className?: string | ((item: T) => string);
  thClassName?: string;
  headClassName?: string;
  orderable?: boolean;
  render: (item: T, options: RenderOptions) => string | ReactNode;
};

export type Pagination = {
  total: number;
  page: number;
  perPage: number;
  orderBy?: number;
  order?: "ASC" | "DESC";
};

type PropsType<T> = {
  name?: string;
  grid?: boolean;
  anchorPos?: number;
  columns: Column<T>[];
  data: T[];
  rowIndex?: string;
  switchMode?: boolean;
  onChangeMode?: (v: boolean) => void;
  cellClassName?: (row: T) => string;
  gridClassName?: string;
  className?: string;
  showPagination?: false | "simple" | "full" | true;
  emptyTabText?: string;
  headerPagination?: boolean;
  pagination?: Pagination;
  scrollable?: boolean;
  loading?: boolean;
  onSelect?:
    | {
        icon?: (props: any) => JSX.Element;
        label: string | ReactNode;
        callback: (items: T[]) => void;
      }[]
    | ((items: T[]) => void);
  onClick?: (item: T) => void;
  onChangeOrder?: (columnIndex: number, direction: "ASC" | "DESC") => void;
  onChangePage?: (page: number) => void;
  onChangePageSize?: (size: number) => void;
  onFetchExportData?: (pagination: Pagination) => Promise<T[]>;
  exportDefaultHeader?: string[];
  exportUniqKey?: string;
  subObjectHeader?: { [key: string]: string[] };
  orderables?: string[];
  moreButtonActiv?: boolean;
  forceGridResponsive?: boolean;
  columnsSeparators?: boolean;
};

export function RenderedTable<T>({
  name,
  columns,
  data,
  rowIndex,
  showPagination,
  emptyTabText,
  pagination,
  loading,
  scrollable,
  onSelect,
  onClick,
  onChangeOrder,
  onChangePage,
  onChangePageSize,
  grid,
  cellClassName,
  gridClassName,
  className,
  onFetchExportData,
  exportDefaultHeader,
  exportUniqKey,
  subObjectHeader,
  switchMode,
  onChangeMode,
  anchorPos,
  orderables,
  forceGridResponsive,
  moreButtonActiv,
  headerPagination,
  columnsSeparators,
}: PropsType<T>) {
  const [columnsDisposed, setColumnsDisposed] = useState(columns);
  const [selected, setSelected] = useState<T[]>([]);
  const parentRef = useRef<HTMLDivElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [parentWidth, setParentWidth] = useState(0);
  const [exportModal, setExportModal] = useState(false);
  const [optionsModal, setOptionsModal] = useState(false);
  const [overlapped, setOverlapped] = useState(-1);
  const [mouseDown, setMouseDown] = useState(false);
  const [allowSearch, setAllowSearch] = useState(true);
  const { t } = useTranslation();

  // Références pour chaque élément
  const refs = useRef<HTMLElement[]>([]);

  // État pour suivre l'élément en cours de déplacement
  const [dragging, setDragging] = useState<number | null>(null);
  const [dragOffset, setDragOffset] = useState(0);

  const handleMouseDown = (e: React.MouseEvent, index: number) => {
    setDragging(index);
    setMouseDown(true);
    const rect = refs.current[0].getBoundingClientRect();
    setDragOffset(e.clientX - rect.left);
    e.preventDefault();
  };
  const handleMouseMove = (e: MouseEvent) => {
    if (dragging !== null && refs.current[dragging]) {
      const deltaX = e.clientX - dragOffset;
      refs.current[dragging].style.transform = `translateX(${deltaX}px)`;
      refs.current.forEach((t, index) => {
        if (index !== dragging) {
          let isOverlapping = doDivsOverlap(refs.current[dragging], t);
          if (isOverlapping) {
            setOverlapped(index);
            setColumnsDisposed(
              swapArrayElements(columnsDisposed, dragging, index)
            );
          }
        }
      });
    }
  };

  const handleMouseUp = () => {
    // Désactiver temporairement les clics supplémentaires
    if (dragging !== null) {
      refs.current[dragging].style.transform = "";
      setDragging(null);
    }
    setMouseDown(false);
    setTimeout(() => {
      setAllowSearch(true);
    }, 500); // Délai avant de réactiver les clics
  };

  useEffect(() => {
    let timer: any;
    if (mouseDown) {
      timer = setTimeout(() => {
        setAllowSearch(false);
      }, 500);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [mouseDown]);

  useControlledEffect(() => {
    // Ajouter l'écouteur d'événements pour le mouvement de la souris et le relâchement de la souris
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    // Retourner une fonction de nettoyage pour enlever les écouteurs d'événements
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging, handleMouseMove, handleMouseUp]);
  const onClickCheckbox = (row: T, a: boolean, e: any) => {
    //Code to manage shift click range
    if ((e.shiftKey || e.ctrlKey) && selected.length > 0) {
      let anchor = selected[selected.length - 1];
      let start = false;
      let newSelection: T[] = [];
      for (const d of data) {
        if (
          (d as any)[rowIndex || "id"] === (anchor as any)[rowIndex || "id"] ||
          (d as any)[rowIndex || "id"] === (row as any)[rowIndex || "id"]
        ) {
          if (start) {
            newSelection.push(d);
            break;
          }
          if (!start) start = true;
        }
        if (start) {
          newSelection.push(d);
        }
      }
      setSelected(
        _.uniqBy(
          [
            ...selected.filter((s) => !newSelection.includes(s)),
            ...(a ? newSelection : []),
            anchor,
          ],
          (s) => (s as any)[rowIndex || "id"]
        )
      );
    } else {
      if (a) {
        setSelected(
          _.uniqBy([...selected, row], (s) => (s as any)[rowIndex || "id"])
        );
      } else {
        setSelected(selected.filter((s) => s !== row));
      }
    }
  };

  useEffect(() => {
    setSelected([]);
  }, [data.length, pagination?.page, pagination?.perPage]);

  useEffect(() => {
    if (onSelect && typeof onSelect === "function") onSelect(selected);
  }, [selected, onSelect]);

  const resizeEvent = useCallback(() => {
    const { offsetWidth } = parentRef.current!.parentNode! as any;
    setParentWidth(offsetWidth);
  }, [setParentWidth]);

  useEffect(() => {
    window.addEventListener("resize", resizeEvent);
    resizeEvent();
    return () => window.removeEventListener("resize", resizeEvent);
  }, [resizeEvent]);

  scrollable = true;

  const responsiveMode = parentWidth < 600; //Work in progress  for responsive mode

  useEffect(() => {
    const timer = setTimeout(() => {
      if (anchorRef.current) {
        anchorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      ref={parentRef}
      className={
        "not-prose text-left border-slate-200 dark:border-slate-700 relative overflow-y-auto overflow-x-hidden h-full relative" +
        (className || "")
      }
    >
      <Modal open={exportModal} onClose={() => setExportModal(false)}>
        <TableExportModal
          tableName={name}
          fetchData={async (pagination: Pagination) =>
            await onFetchExportData!(pagination)
          }
          pagination={pagination}
          onClose={() => setExportModal(false)}
          defaultHeader={exportDefaultHeader}
          uniqKey={exportUniqKey}
          subObjectHeader={subObjectHeader}
        />
      </Modal>

      <Modal open={optionsModal} onClose={() => setOptionsModal(false)}>
        <TableOptionsModal />
      </Modal>

      <div
        className={
          "relative overflow-y-none print:overflow-none " +
          (scrollable ? "h-full " : "")
        }
        style={{
          minHeight: 100,
        }}
      >
        {loading && (
          <div className="absolute m-auto left-0 top-0 right-0 bottom-0 w-6 h-6 text-center z-10">
            <Loader color="text-blue-500" />
          </div>
        )}

        {orderables && (
          <div
            className={
              "float-left flex flex-row  " +
              (responsiveMode ? "w-full" : "max-w-sm  ")
            }
          >
            {/* <Button
              onClick={() => {
                if (gridLimit) {
                  setGridSize(
                    `grid gap-4 grip-cols-1 ${Object.entries(gridLimit)
                      .map(([key, value]) => `${key}:grid-cols-${value}`)
                      .join(" ")}`
                  );
                }
              }}
            >
              CLICK ME
            </Button> */}
            <Select
              size="sm"
              value={pagination?.orderBy}
              className="grow w-full my-2  "
              onChange={(e) => {
                if (onChangeOrder) {
                  onChangeOrder(
                    parseInt(e.target.value),
                    pagination?.order || "DESC"
                  );

                  // go back to first selection in select
                  e.target.selectedIndex = 0;
                }
              }}
            >
              {/* <option value="">
                {"Trier par "}
                {columnsDisposed[pagination?.orderBy as number]?.title ?? "..."}
              </option> */}
              {orderables &&
                orderables.map((c, i) => (
                  <option key={i} value={i}>
                    {c}
                  </option>
                ))}
            </Select>
            <Select
              size="sm"
              value={pagination?.order}
              className="shrink-0 !w-auto -ml-px my-2"
              onChange={(e) => {
                if (onChangeOrder)
                  onChangeOrder(
                    pagination?.orderBy || 0,
                    e.target.value as "ASC" | "DESC"
                  );
              }}
            >
              <option value={"DESC"}>Décroissant</option>
              <option value={"ASC"}>Croissant</option>
            </Select>
          </div>
        )}

        {switchMode && !responsiveMode && !forceGridResponsive && (
          <div className="float-right ml-2">
            <Button
              className="my-2 opacity-60"
              theme="secondary"
              size="sm"
              icon={({ className }) => <TableIcon className={className} />}
              onClick={() => {
                if (onChangeMode) {
                  onChangeMode(false);
                }
              }}
            />
          </div>
        )}
        {onFetchExportData && (
          <div className="float-right ml-2 print:invisible">
            <Button
              className="my-2"
              theme="default"
              size="sm"
              icon={(p) => <DownloadIcon {...p} />}
              onClick={() => setExportModal(true)}
            >
              Export
            </Button>
          </div>
        )}

        {
          // TODO add options to change columnsDisposed order
          false && !grid && !responsiveMode && (
            <div className="float-right ml-2">
              <Button
                className="my-2"
                theme="default"
                size="sm"
                icon={(p) => <CogIcon {...p} />}
                onClick={() => setOptionsModal(true)}
              >
                Options
              </Button>
            </div>
          )
        }
        {headerPagination && pagination && showPagination === "full" && (
          <div>
            <TablePagination
              pagination={pagination}
              dataLength={data.length}
              onChangePage={onChangePage}
              onChangePageSize={scrollable ? undefined : onChangePageSize}
              loading={loading}
            />
          </div>
        )}
        <table
          className={
            "relative z-0 border-collapse w-auto min-w-full" +
            (loading ? " opacity-75 animate-pulse " : "") +
            (scrollable ? " scrollable h-full " : "") +
            (responsiveMode ? " w-full " : "")
          }
        >
          {!responsiveMode &&
            !grid &&
            columnsDisposed.map((c) => c.title || "").join("P") && (
              <thead>
                <tr>
                  {onSelect && (
                    <th
                      className={
                        "w-8 shrink-0 relative " +
                        (scrollable
                          ? " sticky top-0 bg-slate-50 dark:bg-slate-800 "
                          : "")
                      }
                    >
                      <div
                        className="absolute z-10 mt-1 top-0 left-0 "
                        style={{
                          boxShadow: "40px 0 20px #F8FAFC",
                        }}
                      >
                        {selected.length > 0 &&
                          typeof onSelect !== "function" && (
                            <DropdownButton
                              theme="primary"
                              size="sm"
                              /* placeholder={`${selected.length || 0} item${
                                selected.length > 1 ? "s" : ""
                              }`} */
                              options={onSelect.map((a) => ({
                                value: "customer",
                                label: (
                                  <span
                                    onClick={() => a.callback(selected)}
                                    className="flex items-center p-2 font-normal"
                                  >
                                    {a.icon && (
                                      <a.icon className="h-4 w-4 mr-2" />
                                    )}
                                    {a.label}
                                  </span>
                                ),
                              }))}
                            />
                          )}
                      </div>
                    </th>
                  )}
                  {columnsDisposed.map((column, i) => (
                    <th
                      onMouseDown={(e) => handleMouseDown(e, i)}
                      onMouseUp={(e) => handleMouseUp()}
                      ref={(el) => (refs.current[i] = el as HTMLElement)}
                      style={{
                        cursor: "pointer",
                        //cursor: dragging === i ? "grabbing" : "grab",
                        userSelect: "none",
                        background: dragging === i ? "rgb(241 245 249)" : "",
                        zIndex: dragging === i ? "1" : 0,
                      }}
                      key={i}
                      className={
                        "font-medium p-2 py-1  " +
                        (column.orderable
                          ? "cursor-pointer hover:bg-opacity-75 "
                          : "") +
                        (scrollable
                          ? " sticky top-0 dark:bg-slate-800 z-10 "
                          : "") +
                        (column.thClassName || "") +
                        (overlapped === i ? "bg-slate-50 " : "bg-slate-50 ")
                      }
                      onClick={() => {
                        if (column.orderable && allowSearch) {
                          onChangeOrder &&
                            onChangeOrder(
                              i,
                              pagination?.order === "ASC" ? "DESC" : "ASC"
                            );
                        }
                      }}
                    >
                      <div
                        className={
                          "items-center flex text-blue-500 table-hover-sort-container  " +
                          (column.headClassName || "")
                        }
                      >
                        <InfoSmall
                          className="!text-slate-400 text-center w-full"
                          noColor={pagination?.orderBy === i}
                        >
                          {column.title}
                        </InfoSmall>
                        {column.orderable && (
                          <div className="w-8 flex items-center ml-1">
                            {pagination?.orderBy === i &&
                              pagination.order === "DESC" && (
                                <ArrowSmUpIcon className="h-4 w-4 text-blue-500 inline" />
                              )}
                            {pagination?.orderBy === i &&
                              pagination.order !== "DESC" && (
                                <ArrowSmDownIcon className="h-4 w-4 text-blue-500 inline" />
                              )}
                            {pagination?.orderBy !== i && column.orderable && (
                              <ArrowSmDownIcon className="table-hover-sort h-4 w-4 text-slate-500 opacity-50 inline" />
                            )}
                          </div>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
            )}
          <tbody className="overflow-hidden ">
            {data.length === 0 && !loading && (
              <tr>
                <td colSpan={columnsDisposed.length + (onSelect ? 1 : 0)}>
                  <div
                    className={
                      " p-4 text-center" +
                      (scrollable
                        ? ""
                        : "bg-white dark:bg-slate-700 border border-slate-200 dark:border-slate-600")
                    }
                  >
                    <Info>
                      {emptyTabText ? emptyTabText : t("general.tables.empty")}
                    </Info>
                  </div>
                </td>
              </tr>
            )}
            {!!grid && (
              <tr>
                <td
                  className={
                    gridClassName
                      ? gridClassName
                      : "grid gap-4 grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4"
                  }
                >
                  {data.map((row, i) => {
                    if (onSelect && !rowIndex)
                      throw new Error(
                        "rowIndex is required when onSelect is defined"
                      );
                    const isSelected = selected
                      .map((a) => (a as any)[rowIndex || "id"])
                      .includes((row as any)[rowIndex || "id"]);
                    return (
                      <div
                        key={i}
                        className={
                          "flex flex-row items-center" +
                          (columnsDisposed[0].className || "")
                        }
                      >
                        {onSelect && (
                          <Checkbox
                            className="mr-2"
                            value={isSelected}
                            onChange={(a, e) => onClickCheckbox(row, a, e)}
                          />
                        )}
                        <div
                          className={
                            "w-full " +
                            (isSelected
                              ? " bg-blue-200 dark:bg-blue-800 "
                              : " bg-slate-200 dark:bg-slate-700 ") +
                            (onClick
                              ? "cursor-pointer hover:bg-opacity-75 "
                              : "") +
                            (cellClassName?.(row) || "")
                          }
                          onClick={() => onClick && onClick(row)}
                        >
                          {columnsDisposed[0].render(row, {
                            responsive: false,
                          })}
                        </div>
                      </div>
                    );
                  })}
                </td>
              </tr>
            )}
            {!grid &&
              data.map((row, i) => {
                if (onSelect && !rowIndex)
                  throw new Error(
                    "rowIndex is required when onSelect is defined"
                  );
                const isSelected = selected
                  .map((a) => (a as any)[rowIndex || "id"])
                  .includes((row as any)[rowIndex || "id"]);
                return (
                  <tr
                    key={i}
                    onClick={() => onClick && onClick(row)}
                    className={`${
                      onClick ? " cursor-pointer hover:opacity-75" : ""
                    }`}
                  >
                    {onSelect && (
                      <td className="w-8">
                        <Checkbox
                          className="mr-2"
                          value={isSelected}
                          onChange={(a, e) => onClickCheckbox(row, a, e)}
                        />
                      </td>
                    )}
                    {responsiveMode && (
                      <td className={`m-0 p-0 height-table-hack`}>
                        <div
                          className={
                            "mb-2 m-0 p-0 border p-2 " +
                            (i % 2
                              ? isSelected
                                ? "dark:bg-opacity-90 bg-opacity-90 "
                                : "dark:bg-opacity-25 bg-opacity-25 "
                              : "") +
                            (isSelected
                              ? " bg-blue-200 dark:bg-blue-800 "
                              : " bg-white dark:bg-slate-700 ")
                          }
                        >
                          {columnsDisposed.map((cell, j) => (
                            <div
                              className="flex flex-row items-center h-16 space-x-2"
                              key={j}
                            >
                              {columnsDisposed.some((a) => a.title) && (
                                <div className="grow">
                                  {cell.title && (
                                    <Info className="uppercase">
                                      {cell.title}
                                    </Info>
                                  )}
                                </div>
                              )}
                              <div className="overflow-hidden">
                                {cell.render(row, { responsive: true })}
                              </div>
                            </div>
                          ))}
                        </div>
                      </td>
                    )}
                    {!responsiveMode &&
                      columnsDisposed.map((cell, j) => {
                        const jFirst = j === 0;
                        const jLast = j === columnsDisposed.length - 1;
                        const iFirst = i === 0;
                        const iLast = i === data.length - 1;
                        return (
                          <td
                            key={j}
                            className="m-0 p-0 height-table-hack overflow-hidden"
                          >
                            <div
                              className={
                                `h-full w-full flex items-center border-t border-slate-200 dark:border-slate-600 ${
                                  columnsSeparators
                                    ? "border-r-2 border-r-slate-100"
                                    : ""
                                }` +
                                (i % 2
                                  ? isSelected
                                    ? "dark:bg-opacity-90 bg-opacity-90 "
                                    : "dark:bg-opacity-25 bg-opacity-25 "
                                  : "") +
                                ((jFirst &&
                                  " border-l border-r border-r-slate-100") ||
                                  "") +
                                ((jLast && " border-r border-r-slate-100") ||
                                  "") +
                                ((iLast && " border-b") || "") +
                                ((iFirst && jFirst && " rounded-tl ") || "") +
                                ((iFirst && jLast && " rounded-tr ") || "") +
                                ((iLast && jFirst && " rounded-bl ") || "") +
                                ((iLast && jLast && " rounded-br ") || "") +
                                ((!iLast &&
                                  !jLast &&
                                  columnsSeparators &&
                                  "") ||
                                  " border-r border-r-slate-100") +
                                `${
                                  isSelected
                                    ? " bg-blue-200 dark:bg-blue-800 "
                                    : " bg-white dark:bg-slate-700 "
                                }` +
                                `${
                                  cell.className
                                    ? typeof cell.className === "function"
                                      ? cell.className(row)
                                      : cell.className
                                    : ""
                                }`
                              }
                            >
                              <Base
                                className={
                                  (jFirst ? "pl-2 " : "") +
                                  (jLast ? "pr-2 " : "") +
                                  "w-full py-2 px-1 inline-flex items-center h-full pr-4 " +
                                  (cellClassName?.(row) || "")
                                }
                              >
                                {cell.render(row, { responsive: false })}
                              </Base>
                            </div>
                          </td>
                        );
                      })}
                  </tr>
                );
              })}
          </tbody>
          {!!pagination && showPagination && (!loading || !!data.length) && (
            <tfoot>
              <tr>
                <td
                  colSpan={columnsDisposed.length + (onSelect ? 1 : 0)}
                  className={
                    "items-center pl-2 py-2 pr-0 text-slate-500 dark:text-slate-400 " +
                    (scrollable
                      ? " sticky bottom-0 bg-slate-50 dark:bg-slate-800 z-10 "
                      : "")
                  }
                >
                  {showPagination === "full" ? (
                    <TablePagination
                      pagination={pagination}
                      dataLength={data.length}
                      onChangePage={onChangePage}
                      onChangePageSize={
                        scrollable ? undefined : onChangePageSize
                      }
                      loading={loading}
                    />
                  ) : (
                    <TablePaginationSimple
                      pagination={{
                        ...pagination,
                        moreButtonActiv: moreButtonActiv,
                      }}
                      dataLength={data.length}
                      onChangePage={onChangePage}
                      loading={loading}
                    />
                  )}
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>

      <div
        ref={anchorRef}
        id="BIDULE"
        className="w-px"
        style={{
          position: "absolute",
          overflowY: "hidden",
          zIndex: 9988,
          height: `${
            anchorPos && anchorPos > 0
              ? anchorPos + // eslint-disable-next-line no-restricted-globals
                (screen.height / 176) * 0.63 * 176
              : anchorPos
          }px`,
          color: "red",
          left: 0,
          top: 0,
        }}
      ></div>
    </div>
  );
}
