import { BookRender } from "@atoms/book";
import { Button } from "@atoms/button/button";
import { InputCounter } from "@atoms/input/input-counter";
import Select from "@atoms/input/input-select";
import { BaseBoldSmall, InfoSmall, InfoSmallBold } from "@atoms/text";
import { OrderItem } from "@features/customer/type";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { useBasket } from "@features/products/state/use-basket";
import { useBaskets } from "@features/products/state/use-baskets";
import {
  formatAmount,
  getCommonProductTooltip,
} from "@features/utils/format/strings";
import { ShoppingCartIcon } from "@heroicons/react/outline";

export default function OrderProductCard({
  orderItem,
  editable,
}: {
  orderItem: OrderItem;
  editable?: boolean;
}) {
  const { current } = useBaskets();
  const {
    products: fullProducts,
    add,
    remove,
    changeStatu,
  } = useBasket(current || "");
  const { current: currentShop } = useShopLocations();
  const basketSize = fullProducts.find(
    (e) => e.product.id === orderItem.product.id
  )?.count;

  return (
    <div
      //className={"flex grow my-2 " + editable ? "h-32" : "h-32"}
      className="flex grow h-46 my-2"
      onClick={() => {}}
    >
      <div className="shrink-0 h-full py-2 relative sm:w-32 w-24 flex justify-center border-r border-slate-200 dark:border-slate-800 bg-cover bg-center">
        <div className="px-2 h-24 flex items-center justify-center">
          <BookRender
            src={orderItem.product.imageURL}
            productType={orderItem.product.modeGest}
            textInfoPos="on-img"
          />
        </div>
      </div>
      <div className="grow flex flex-col space-y-1 px-1 overflow-hidden">
        <InfoSmallBold
          className="font-semibold whitespace-nowrap overflow-hidden text-ellipsis cursor-text"
          data-html={true}
          data-tooltip={getCommonProductTooltip(
            orderItem.product,
            orderItem.product.modeGest
          )}
        >
          {orderItem.product.designation}
        </InfoSmallBold>

        <div className="grow flex flex-row">
          <div className="grow flex flex-col overflow-hidden">
            <InfoSmallBold className="block cursor-text">
              {orderItem.product.id}
            </InfoSmallBold>
            <InfoSmall className="whitespace-nowrap overflow-hidden text-ellipsis">
              {orderItem.product.qteDispoVente} disponible à la vente{" "}
              {orderItem.product.qteDispoVente !==
              orderItem.product.qteDispoVenteFutur
                ? `(${orderItem.product.qteDispoVenteFutur} future)`
                : ""}
            </InfoSmall>
            {orderItem.product.dispoHabit && (
              <InfoSmall className="whitespace-nowrap overflow-hidden text-ellipsis">
                {orderItem.product.dispoHabit} - {orderItem.product.fournHabit}
              </InfoSmall>
            )}
            <InfoSmall>
              {formatAmount(
                orderItem.product.prixPublic,
                currentShop?.devisSymb,
                currentShop?.devisNbDecim
              )}
              TTC
            </InfoSmall>

            {editable ? (
              <Select
                size="sm"
                className="shrink-0"
                value={orderItem.statu}
                data-tooltip={`${orderItem.statu} - ${
                  orderItem.statu === "ACO"
                    ? "À commander"
                    : orderItem.statu === "RES"
                    ? "Réservation client"
                    : "Prise sur stock"
                }`}
                onChange={(e) => {
                  e.stopPropagation();
                  const productID = fullProducts.findIndex(
                    (el) => el.product.id === orderItem.product.id
                  );

                  changeStatu(
                    fullProducts[productID].product,
                    orderItem.qte,
                    e.target.value as "ACO" | "RES" | "STO"
                  );
                }}
                /* onChange={(e) => {
                props.onChange(
                  props.products.map((p) => {
                    if (p.product.id === orderItem.product.id) {
                      return { ...p, statu: e.target.value };
                    }
                    return p;
                  })
                );
              }} */
              >
                <option value="ACO">ACO - À commander</option>
                <option value="RES">RES - Réservation client</option>
                <option value="STO">PS - Prise sur stock</option>
              </Select>
            ) : (
              <InfoSmallBold>Action: {orderItem.statu} </InfoSmallBold>
            )}
          </div>
        </div>

        <div
          className={
            "flex space-x-3 " + editable ? "flex-col-reverse" : "flex-row"
          }
        >
          <div className="w-full flex flex-row gap-2 justify-center">
            <BaseBoldSmall>Quantité: {orderItem.qte}</BaseBoldSmall>
            <BaseBoldSmall>-</BaseBoldSmall>
            <BaseBoldSmall>
              Total:{" "}
              {formatAmount(
                orderItem.pxUnit * orderItem.qte,
                currentShop?.devisSymb,
                currentShop?.devisNbDecim
              )}
            </BaseBoldSmall>
          </div>
          {!basketSize && (
            <Button
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                add(orderItem.product, 1);
              }}
              icon={({ className }) => (
                <ShoppingCartIcon className={className} />
              )}
            />
          )}
          {basketSize && (
            <div className="bg-amber-500 self-center">
              <InputCounter
                size="sm"
                confirmOnZero
                deleteIconOnZero
                value={basketSize || 0}
                onChange={(value) => {
                  if (value > basketSize!)
                    add(orderItem.product, value - basketSize!);
                  else remove(orderItem.product, basketSize! - value);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
