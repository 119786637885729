import { Frame } from "@atoms/layout/frame";
import { BaseBold, Info, Section, Title } from "@atoms/text";
import {
  ArrowCircleLeftIcon,
  ArrowNarrowRightIcon,
  CheckCircleIcon,
} from "@heroicons/react/outline";
import { Button } from "@atoms/button/button";
import { useOrder } from "@features/customer/state/use-order";
import {
  Client,
  Order,
  OrderItem,
  ProductTypeOrder,
} from "@features/customer/type";
import { useBasket } from "@features/products/state/use-basket";
import { useBaskets } from "@features/products/state/use-baskets";
import { ROUTES } from "@features/routes";
import { formatAmount } from "@features/utils/format/strings";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { Form } from "@molecules/form";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { ClientSelection } from "../client/select-client";
import OrderReview from "./order-product-list";
import { useSetRecoilState } from "recoil";
import {
  HistoryCommandModalAtom,
  NumCliHistoryModalAtom,
} from "@molecules/history-command-modal";
import { FinalizeOrderAtom } from "./finalize-order-modal";

export const CreateOrder = (props: {
  onOrderCompleted: (command: { client: Client; order: Order }) => void;
}) => {
  const setHistoryCommandModal = useSetRecoilState(HistoryCommandModalAtom);
  const setNumCliModal = useSetRecoilState(NumCliHistoryModalAtom);

  const { id: currentBasket } = useParams<{ id: string }>();
  const { basketCustomer, products, getPrice } = useBasket(currentBasket || "");

  const [currentClient, setCurrentClient] = useState(basketCustomer);
  const setFinalizeOrderModal = useSetRecoilState(FinalizeOrderAtom);

  const { baskets, removeBasket, changeBasketCustomer } = useBaskets();

  const [hasClientDefined, setClientDefined] = useState(false);
  const navigate = useNavigate();

  const [updateCustomerForm, setUpdateCustomerForm] = useState(true);
  const { enhanceOrder } = useOrder("");

  const [commandBasket, setCommandBasket] = useState<OrderItem[]>(
    products
      .map((p) => {
        if (!p.aside) {
          return {
            pxUnit: p.product.prixVente,
            qte: p.count,
            statu: p.statu || "ACO",
            product: p.product as ProductTypeOrder,
          };
        }
        return undefined;
      })
      .filter((el) => el !== undefined) as OrderItem[]
  );

  useControlledEffect(() => {
    if (currentClient.numCli) {
      setClientDefined(true);
      setNumCliModal(currentClient.numCli);
      changeBasketCustomer(currentBasket || "", currentClient);
    } else if (currentClient.numCli === null) {
      setClientDefined(false);
    }
  }, [currentClient]);

  useControlledEffect(() => {
    setCommandBasket(
      products
        .map((p) => {
          if (!p.aside) {
            return {
              pxUnit: p.product.prixVente,
              qte: p.count,
              statu: p.statu || "ACO",
              product: p.product as ProductTypeOrder,
            };
          }
          return undefined;
        })
        .filter((el) => el !== undefined) as OrderItem[]
    );
  }, [products]);

  useControlledEffect(
    () => () =>
      baskets.forEach((basket) => {
        if (basket.temporary) removeBasket(basket.id);
      }),
    []
  );
  return (
    <div className="flex flex-col justify-start mx-1 sm:mx-6">
      <div className="grow flex flex-col-reverse gap-4 sm:gap-0 sm:flex-col mt-2 md:flex-row md:justify-around">
        <div className="grow">
          <Title>Commande client</Title>
        </div>

        <div className="flex mt-2 md:mt-0">
          {/*Trouver façon plus clean de le faire*/}
          <Button
            className="sm:invisible"
            theme="danger"
            onClick={() => {
              navigate(ROUTES.Products);
            }}
            icon={(p) => <ArrowCircleLeftIcon {...p} />}
          />
          <Button
            className="invisible sm:visible"
            theme="danger"
            onClick={() => {
              navigate(ROUTES.Products);
            }}
            icon={(p) => <ArrowNarrowRightIcon {...p} />}
          >
            Retour
          </Button>
        </div>
      </div>

      <div className="flex flex-col mt-4 md:flex-row justify-start h-full w-full md:mt-6 space-y-6 md:space-y-0 md:space-x-6 ">
        <div className="flex flex-col md:w-1/3">
          <Section>Client</Section>

          <Frame>
            <ClientSelection
              client={currentClient}
              defined={hasClientDefined}
              onChange={(client) => setCurrentClient(client)}
            />

            {hasClientDefined && (
              <div className="mt-4 mb-2">
                <Form
                  onChange={(v) => setUpdateCustomerForm(v.update)}
                  value={{ update: updateCustomerForm }}
                  fields={[
                    {
                      key: "update",
                      label: "Mettre à jour la fiche client",
                      placeholder: "Mettre à jour",
                      type: "boolean",
                    },
                  ]}
                />
              </div>
            )}
          </Frame>

          <div className="mt-4">
            <Button
              theme="outlined"
              className="print:hidden"
              onClick={() => setHistoryCommandModal(true)}
            >
              Historique commandes
            </Button>
          </div>
        </div>

        <div
          className={
            "grow overflow-hidden flex flex-col " +
            (hasClientDefined ? "" : "pointer-events-none opacity-50")
          }
        >
          <Section>
            Commande
            <BaseBold className="float-right">
              <Info>TOTAL</Info> {formatAmount(getPrice())}
            </BaseBold>
          </Section>

          <Frame>
            <OrderReview
              products={commandBasket}
              enableBuy={false}
              onChange={(t) => {
                setCommandBasket(t);
              }}
            />
          </Frame>

          <div className="mt-4">
            <Button
              disabled={!hasClientDefined}
              className="w-auto float-right"
              size="lg"
              shortcut={["enter"]}
              onClick={async () => {
                const result = await enhanceOrder({
                  ...currentClient,
                  items: commandBasket,
                  panie: currentBasket,
                  mtAcomp: 0,
                  updateCustomerForm: updateCustomerForm,
                });
                if (result) {
                  if (currentBasket)
                    setFinalizeOrderModal({
                      open: true,
                      client: currentClient,
                      items: result.items,
                      updateCustomerForm: updateCustomerForm,
                      mtAcomp: result.mtAcomp || 0,
                      panie: result.panie || "",
                      basket: currentBasket,
                      comm: "",
                    });
                } else {
                  toast.error("Impossible de valoriser le panier");
                }
              }}
              icon={(p) => <CheckCircleIcon {...p} />}
            >
              Finaliser commande
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
