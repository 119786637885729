import { fetchServer } from "@features/utils/fetch-server";
import { DroitRetour, ProductAvailability, Taxe } from "../types";
import toast from "react-hot-toast";

export class CommonApiClient {
  static getCountries = async (clientId: string) => {
    const data = await fetchServer(`/country`, {
      method: "GET",
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result;
  };

  static getTaxes = async () => {
    const data = await fetchServer(`/tax`, {
      method: "GET",
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as Taxe[];
  };

  static getAvailibility = async (codeTypeProd?: string) => {
    const data = await fetchServer(
      "/availability" +
        `${codeTypeProd ? "?codeTypeProd=" + codeTypeProd : ""}`,
      {
        method: "GET",
      }
    );
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as ProductAvailability[];
  };

  static getCalculatedDroitRetour = async (
    codeTypeAchat: string,
    dateRecep: string
  ) => {
    const data = await fetchServer("/returnrightscalculation", {
      method: "POST",
      body: JSON.stringify({
        codeTypeAchat: codeTypeAchat,
        dateRecep: dateRecep,
      }),
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as DroitRetour;
  };

  static getAmountCalculationByTaxe = async (
    orig: string,
    code: string,
    ht: number,
    ttc: number
  ) => {
    const data = await fetchServer("/amountcalculationbytaxe", {
      method: "POST",
      body: JSON.stringify({
        orig: orig,
        code: code,
        ht: +ht,
        ttc: ttc,
      }),
    });
    const result = await data.json();
    if (data.status !== 200) {
      toast.error("Calcul des frais impossibles. Erreur: " + result.title);
      throw new Error(result.title);
    }
    return result as { ht: number; ttc: number };
  };
}
