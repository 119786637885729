import Accordion from "@atoms/accordion";
import { Button } from "@atoms/button/button";
import { Frame } from "@atoms/layout/frame";
import { Page } from "@atoms/layout/page";
import Tabs from "@atoms/tabs";
import { Base, BaseSmall, Title } from "@atoms/text";
import {
  PackageCard,
  ReceptionCard,
  SupplierOrderCard,
  getLibel,
} from "@components/reception/reception-cards";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { useReception } from "@features/reception/state/use-reception";
import { ReceptionUnit } from "@features/reception/types";
import { ROUTES } from "@features/routes";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { Bl, SupplierOrder } from "@features/supplier/types";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { Grid } from "@molecules/table";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { CommonConfirmModalAtom } from "@molecules/confirm-modal";
import { FaExclamationTriangle } from "react-icons/fa";
import {
  InformationCircleIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline";
import { Column } from "@molecules/table/table";
import { toDateISO } from "@features/utils/format/dates";
import { BookRender } from "@atoms/book";
import { ReceptionInfoModalAtom } from "../receptions-modals/reception-info-modal";
import { TableGridSwitch } from "@molecules/table/table-grid-switch";
import { usePersistedState } from "@features/utils/hooks/use-local-storage";

export const SupplierPage = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const {
    refreshAllOngoingReceipt,
    allOngoingReceipt,
    changeSelectedReception,
    getHeaderReceipt,
    getReceipt,
  } = useReception();
  const { getFournBl, getFournOrders } = useSuppliers();
  const { current: shopCodeLieu } = useShopLocations();
  const [allBl, setAllBl] = useState<Bl[]>([]);
  const [allCommand, setAllCommand] = useState<SupplierOrder[]>([]);
  const [gridMode, setGridMode] = usePersistedState(
    "user",
    "supplier-global-grid-mode",
    {
      activated: true,
    }
  );
  const navigate = useNavigate();
  const { codeFourn } = useParams<{
    codeFourn: string;
    raisSocial: string;
  }>();

  const setConfirmModal = useSetRecoilState(CommonConfirmModalAtom);
  const setReceptionInfoModal = useSetRecoilState(ReceptionInfoModalAtom);

  useControlledEffect(() => {
    const loadTab = async () => {
      setAllBl(await getFournBl(codeFourn, "", false, false));
      setAllCommand(
        await getFournOrders(
          shopCodeLieu?.codeLieu,
          codeFourn,
          false,
          false,
          false
        )
      );
    };
    refreshAllOngoingReceipt(undefined, { trait: false, codeFourn: codeFourn });
    loadTab();
  }, [codeFourn]);

  useControlledEffect(() => {
    const timer = setTimeout(() => {
      document.title = `Inférence - ${codeFourn}`;
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const ongoingRecepColumns = [
    {
      title: "Fournisseur",
      render: (el) => (
        <div className="flex gap-2">
          <div className="flex flex-col justify-center items-end w-6 h-6">
            <BookRender src="" productType={el.modeGest} />
          </div>
          <Base>{el.codeFourn}</Base>
        </div>
      ),
    },

    {
      title: "Num. BL",
      render: (el) => el.numBL,
    },

    {
      title: "Num. reception",
      render: (el) => el.numRecep,
    },

    {
      title: "Type reception",
      render: (el) => getLibel(el),
    },
    {
      title: "Nombre de colis",
      render: (el) => (
        <Base className="lg:w-28 md:text-center lg:text-right">
          {el.nbrColis}
        </Base>
      ),
    },

    {
      orderable: true,
      title: "Date",
      render: (el) => toDateISO(el.dateRecep),
    },

    {
      title: "Infos",
      render: (el) => (
        <Button
          onClick={(event) => {
            changeSelectedReception(el);
            setReceptionInfoModal(true);
            event.stopPropagation();
          }}
          icon={({ className }) => (
            <InformationCircleIcon className={className} />
          )}
        />
      ),
    },
  ] as Column<any>[];

  const supplierOrderColumns = [
    {
      title: "Num. commande",
      render: (el) => <Base>{el.numCde}</Base>,
    },
    {
      title: "Date",
      render: (el) => (
        <Base>{new Date(el.dateCreat).toISOString().split("T")[0]}</Base>
      ),
    },
    {
      title: "Nb réf",
      render: (el) => <Base>{el.nbRef}</Base>,
    },
    {
      title: "Nb articles",
      render: (el) => <Base>{el.nbArticle}</Base>,
    },
  ] as Column<any>[];

  const chooseCard = (tabIndex: number, item: any) => {
    switch (tabIndex) {
      case 0:
        return <ReceptionCard item={item as ReceptionUnit} />;
      case 2:
        return <SupplierOrderCard item={item as SupplierOrder} />;
      default:
        return <ReceptionCard item={item} />;
    }
  };

  const chooseData = (tabIndex: number) => {
    switch (tabIndex) {
      case 0:
        return allOngoingReceipt.items;
      case 2:
        return allCommand;
      default:
        return [];
    }
  };
  return (
    <Page>
      <Title>{codeFourn}</Title>
      <div className="flex flex-col-reverse md:flex-row gap-4 mt-6 justify-between ">
        <div className="w-full md:w-1/2 flex flex-col mt-6 justify-end">
          <Tabs
            tabs={[
              {
                value: 0,
                label: `Réceptions en cours (${allOngoingReceipt.pagination.totalRows})`,
              },
              { value: 1, label: `Bons de livraisons (${allBl.length})` },
              {
                value: 2,
                label: `Commandes non clôturées (${allCommand.length})`,
              },
              //{ value: 3, label: "Attendus (3)" },
            ]}
            value={tabIndex}
            onChange={async (v) => {
              console.log(v);
              setTabIndex(v as number);
              if (v === 1)
                setAllBl(await getFournBl(codeFourn, "", false, false));
              if (v === 2)
                setAllCommand(
                  await getFournOrders(
                    shopCodeLieu?.codeLieu,
                    codeFourn,
                    false,
                    false,
                    false
                  )
                );
            }}
          />{" "}
        </div>

        <div className="flex gap-2 mt-6 justify-end">
          {/* <Frame className="flex flex-col gap-2 max-w-xs">
            <BaseSmall className="text-center">
              Les commandes du fournisseur seront dynamiquement associées aux
              articles
            </BaseSmall>
            <Button size="sm">Créer réception sur portefeuille</Button>
          </Frame> */}
          <Frame className="flex flex-col gap-2 justify-between">
            <BaseSmall className="text-center">
              Les commandes du fournisseur seront ignorées.
            </BaseSmall>
            <Button
              onClick={async () => {
                changeSelectedReception({
                  ...(await getHeaderReceipt({
                    codeLieu: shopCodeLieu?.codeLieu || "",
                    orig: "HCDE",
                    codeFourn: codeFourn || "",
                    numBL: "",
                    numCde: "",
                    numColis: "",
                  })),
                  orig: "HCDE",
                });
                navigate(ROUTES.ReceptionHeaderPage);
              }}
              size="sm"
            >
              Créer réception hors commande
            </Button>
          </Frame>
        </div>
      </div>
      <div className="h-full w-full flex flex-col gap-2 my-12">
        <div className="mb-8 pb-8">
          {tabIndex !== 1 ? (
            <TableGridSwitch
              key={tabIndex}
              gridMode={gridMode.activated}
              onChangeMode={(v) => {
                setGridMode({ activated: v });
              }}
              gridClassName={
                "grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6"
              }
              className="grow"
              data={chooseData(tabIndex) as any[]}
              onClick={async (e) => {
                if (e.numRecep && e.numRecep.trim() !== "") {
                  const resReceipt = await getReceipt(e);
                  if (!resReceipt.trait) {
                    if (resReceipt.recepOuver) {
                      setConfirmModal({
                        theme: "danger",
                        icon: () => (
                          <FaExclamationTriangle className="text-red-600 h-6 w-6" />
                        ),
                        open: true,
                        title: "Réception en cours",
                        message:
                          "Cette réception est déjà en cours de traitement par: INFER. Souhaitez-vous continuer ?",
                        onAccept: async () => {
                          changeSelectedReception(resReceipt);
                          navigate(
                            ROUTES.OngoingReception.replace(
                              /:numRecep/,
                              resReceipt.numRecep
                            )
                          );
                        },
                      });
                    } else {
                      changeSelectedReception(resReceipt);
                      navigate(
                        ROUTES.OngoingReception.replace(
                          /:numRecep/,
                          resReceipt.numRecep
                        )
                      );
                    }
                  }
                } else if (tabIndex === 2) {
                  changeSelectedReception(
                    await getHeaderReceipt({
                      codeLieu: shopCodeLieu?.codeLieu || "",
                      orig: "CDE",
                      codeFourn: e.codeFourn,
                      numBL: e.numBL,
                      numColis: "",
                      numCde: e.numCde,
                    })
                  );
                  navigate(ROUTES.ReceptionHeaderPage);
                }
              }}
              renderGrid={(el) => chooseCard(tabIndex, el)}
              tableColumns={
                tabIndex === 0 ? ongoingRecepColumns : supplierOrderColumns
              }
            />
          ) : (
            <Accordion
              panels={allBl.map((bl, index) => {
                return {
                  value: bl.numBL,
                  label: `BL ${bl.numBL} - ${bl.nbrColis} colis - ${
                    new Date(bl.dateCreat).toISOString().split("T")[0]
                  } `,
                  node: (
                    <div className="flex flex-col gap-2">
                      <div className="flex justify-start">
                        <Button
                          size="sm"
                          icon={(p) => <PlusCircleIcon {...p} />}
                          onClick={async (e) => {
                            changeSelectedReception(
                              await getHeaderReceipt({
                                codeLieu: shopCodeLieu?.codeLieu || "",
                                orig: "AE",
                                codeFourn: bl.codeFourn,
                                numBL: bl.numBL,
                                numColis: "",
                                numCde: "",
                              })
                            );
                            navigate(ROUTES.ReceptionHeaderPage);
                          }}
                        >
                          Créer une réception sur le BL complet
                        </Button>
                      </div>
                      <Grid
                        className="grow"
                        data={allBl[index].listSupplierPackage || []}
                        render={(el) => <PackageCard item={el} />}
                        onClick={async (colis) => {
                          changeSelectedReception(
                            await getHeaderReceipt({
                              codeLieu: shopCodeLieu?.codeLieu || "",
                              orig: "COLIS",
                              codeFourn: colis.codeFourn,
                              numBL: colis.numBL,
                              numColis: colis.numColis,
                              numCde: "",
                            })
                          );
                          navigate(ROUTES.ReceptionHeaderPage);
                        }}
                        emptyTabText="Aucun colis pour ce BL"
                      />
                    </div>
                  ),
                };
              })}
            />
          )}
        </div>
      </div>
    </Page>
  );
};
