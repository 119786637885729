import { useRecoilState } from "recoil";
import { SupplierApiClient } from "../api-client/api-client";
import { CommandType, ReturnType } from "../types";
import { LoadingState } from "@features/utils/store/loading-state-atom";

export const useSuppliers = () => {
  const [loading, setLoading] = useRecoilState(
    LoadingState(["useOrder", false])
  );
  const getMotif = async () => {
    return await SupplierApiClient.getMotifRefus();
  };

  const getModesExpedition = async () => {
    return await SupplierApiClient.getModesExpedition();
  };

  const getFournBl = async (
    codeFourn?: string,
    numBL?: string,
    recepOuver?: boolean,
    recepTerm?: boolean
  ) => {
    return await SupplierApiClient.getFournBl(
      codeFourn,
      numBL,
      recepOuver,
      recepTerm
    );
  };

  const getFournBlLines = async (
    codeFourn?: string,
    numBL?: string,
    numColis?: string,
    idColis?: string,
    livrNbRef?: number,
    livrNb?: number
  ) => {
    return await SupplierApiClient.getFournBlLines(
      codeFourn,
      numBL,
      numColis,
      idColis,
      livrNbRef,
      livrNb
    );
  };

  const getFournOrders = async (
    codeLieuLivr?: string,
    codeFourn?: string,
    trait?: boolean,
    clot?: boolean,
    recepValid?: boolean
  ) => {
    return await SupplierApiClient.getFournOrders(
      codeLieuLivr,
      codeFourn,
      trait,
      clot,
      recepValid
    );
  };

  const getFournOrderLines = async (numCde: string) => {
    return await SupplierApiClient.getFournOrderLines(numCde);
  };

  const createRetour = async (retour: ReturnType, codeLieu: string) => {
    setLoading(true);
    const res = await SupplierApiClient.createRetour(retour, codeLieu);
    setLoading(false);
    return res;
  };

  const createOrder = async (order: CommandType, codeLieu: string) => {
    setLoading(true);
    const res = await SupplierApiClient.createOrder(order, codeLieu);
    setLoading(false);
    return res;
  };

  return {
    loading,
    getFournBl,
    getFournBlLines,
    getFournOrders,
    getFournOrderLines,
    getMotif,
    getModesExpedition,
    createRetour,
    createOrder,
  };
};
