import { BaseBoldSmall, Title } from "@atoms/text";
import { useRef, useState } from "react";
import "./index.css";
import { useRecoilState } from "recoil";
import { ImageModalAtom } from "@molecules/image-modal";

export const BookRender = (props: {
  src?: string;
  alt?: string;
  fallback?: string;
  productType?: string;
  textInfoPos?: "no-text" | "on-img" | "under-img";
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [errorLoading, setErrorLoading] = useState(false);
  const [isLongPress, setIsLongPress] = useState(false);
  const timerRef = useRef<number | null>(null);
  const [imageModal, setImageModal] = useRecoilState(ImageModalAtom);

  const openImageModal = () => {
    if (!imageModal.open && !errorLoading && props.src && props.alt) {
      setImageModal({
        ...imageModal,
        open: true,
        src: props.src,
        alt: props.alt,
      });
    }
  };

  const updateBookRender = (img: HTMLImageElement) => {
    const container = containerRef.current;
    // if (!errorLoading) {
    //   container!.style.width = window.screen.width > 400 ? "100%" : "80%";
    // } else {
    //   container!.style.width = window.screen.width > 400 ? "60%" : "40%";
    // }
    container!.style.width = !errorLoading ? "100%" : "80%";
    container!.style.height = !errorLoading ? "100%" : "80%";

    //Get image aspect ratio
    const ratio = img.naturalWidth / img.naturalHeight;

    //Get container size
    const containerWidth = container?.offsetWidth || 100;
    const containerHeight = container?.offsetHeight || 100;

    //Update container size keeping either width or height at 100%
    if (ratio * containerHeight > containerWidth) {
      container!.style.height = `${containerWidth / ratio}px`;
    } else {
      container!.style.width = `${ratio * containerHeight}px`;
    }
  };

  const handleTouchStart = () => {
    timerRef.current = window.setTimeout(() => {
      setIsLongPress(true);
      //console.log("Appui prolongé détecté");
      openImageModal();
    }, 2000);
  };

  const handleTouchEnd = () => {
    if (timerRef.current !== null) {
      clearTimeout(timerRef.current);
    }
    if (!isLongPress) {
      //Pourra servir
    }
    setIsLongPress(false);
  };

  const handleClick = () => {
    openImageModal();
  };

  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onClick={handleClick}
      ref={containerRef}
      className={`${
        errorLoading ? "missing_book_container" : "render_book_container"
      } opacity-0 transition-opacity duration-500 ease-in-out relative hover:cursor-pointer`}
    >
      <div
        className={
          errorLoading ? "missing_book_content" : "render_book_content"
        }
      >
        <img
          src={props.src}
          alt={props.alt || "Couverture"}
          onMouseEnter={(e) => {
            updateBookRender(e.target as HTMLImageElement);
          }}
          onLoad={(e) => {
            updateBookRender(e.target as HTMLImageElement);
            setTimeout(() => {
              containerRef.current?.classList?.remove("opacity-0");
            }, 100);
          }}
          onError={(e) => {
            (e.target as HTMLImageElement).src =
              props.fallback || getProductPicture(props.productType || "");
            setErrorLoading(true);
            setTimeout(() => {
              containerRef.current?.classList?.remove("opacity-0");
            }, 100);
          }}
        />
      </div>
      {errorLoading && props.textInfoPos === "on-img" && (
        <div className="absolute inset-0 flex items-center justify-center">
          <BaseBoldSmall> Visuel non disponible </BaseBoldSmall>
        </div>
      )}
      {errorLoading && props.textInfoPos === "under-img" && (
        <Title className="text-center mt-2">Visuel non disponible</Title>
      )}
    </div>
  );
};

const getProductPicture = (productType: string) => {
  switch (productType) {
    case "L":
      return "/medias/missing-book.svg";
    case "N":
      return "/medias/missing-book.svg";
    case "S":
      return "/medias/missing-press.svg";
    case "D":
      return "/medias/missing-disc.svg";
    case "P":
      return "/medias/missing-paper.svg";
    default:
      return "/medias/question-mark.svg";
  }
};
